import { ChangeEvent } from "react";
import './VolumeSlider.css';

type VolumeSliderProps = {
  onChange: (value:number) => void;
  value: number;
}
function VolumeSlider({
  onChange,
  value,
}:VolumeSliderProps):JSX.Element {

  const handleChange = (e:ChangeEvent<HTMLInputElement>) => {
    onChange(parseFloat(e.target.value))
  }
  return (
    <div className="VolumeSlider">
      <div 
        className="VolumeSlider-bg"
        style={{
          width: `${value * 100}%`
        }}
      />
      <input 
        className="VolumeSlider-input"
        type="range" 
        name="volume"
        value={value}
        onChange={handleChange}
        max={1}
        min={0.027}
        step={0.027}
      />
    </div>
  );
}

export default VolumeSlider;
