import './RadioPage.css';
import 'components/Player/Player.css';
import {Player, PlayButton, CurrentSong, VolumeSlider} from 'components/Player';
import { useRef, useState } from 'react';
import ReactHowler from 'react-howler'
import useStationInfo from 'hooks/useStationInfo';

function RadioPage() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0.9);
  const [isMuted, setIsMuted] = useState(false);
  const playerRef = useRef<ReactHowler | null>(null);
  const {now_playing} = useStationInfo();

  const togglePlay = () => {
    if (isPlaying) {
      // stream already started;
      toggleMute();
    } else {
      // first time;
      setIsPlaying(true);
    }
  }

  const setVolume = (value: number) => {
    setVolumeLevel(value);
  }

  const toggleMute = () => {
    setIsMuted(!isMuted);
  }

  return (
    <div className="player-container">
      <Player />
      <VolumeSlider 
        value={volumeLevel}
        onChange={setVolume}
      />
      <PlayButton
        onClick={togglePlay}
        isStopped={isMuted || !isPlaying}
      />
      <CurrentSong>
        {now_playing?.song.text}
      </CurrentSong>
      <ReactHowler
        src='https://s33.radiolize.com/radio/8190/radio.mp3'
        playing={isPlaying}
        preload={true}
        html5={true}
        mute={isMuted}
        volume={volumeLevel}
        ref={playerRef}
      />
    </div>
  );
}

export default RadioPage;
