import { Link } from 'react-router-dom';
import './AppHeader.css';
import logo from './logo.svg'


function AppHeader() {

  return (
    <div className="AppHeader">
      <img src={logo} alt="logo" />
      <nav>
        <Link to="/">Radio</Link>
        <Link to="/about">About</Link>
        <Link to="/donate">Donate</Link>
      </nav>
    </div>
  );
}

export default AppHeader;
