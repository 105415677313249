import './App.css';
import AppHeader from 'components/AppHeader/AppHeader';
import { Route, Routes } from 'react-router-dom';
import RadioPage from 'pages/RadioPage/RadioPage';
import AboutPage from 'pages/AboutPage/AboutPage';

function App() {
  return (
    <div className="App">
      <div className="App-gradient">
        <AppHeader />
        <Routes>
          <Route element={<RadioPage />} path="/" />
          <Route element={<AboutPage />} path="/about" />
          <Route element={<h1>Donate</h1>} path="/donate" />
        </Routes>
      </div>
    </div>
  );
}

export default App;
