
type CurrentSongProps = {
  children: React.ReactNode;
}
function CurrentSong({
  children
}:CurrentSongProps):JSX.Element {
  return (
    <div className="CurrentSong">
      <div className="CurrentSong-wrapper">
        <p className="CurrentSong-text">{children}</p>
        <p className="CurrentSong-text">{children}</p>
      </div>
    </div>
  );
}

export default CurrentSong;
