/* eslint-disable react-hooks/exhaustive-deps*/
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";
import { StationInfo } from "../interfaces/StationInfo";

const STATION_ID = 84;

const getStationInfo = (stationId:number):Promise<StationInfo> => {
  return axios
    .get(`https://s33.radiolize.com/api/nowplaying/${stationId}`)
    .then(({data}) => data);
}

export default function useStationInfo(){
  const {data, refetch} = useQuery(['station_info'], () => getStationInfo(STATION_ID));

  useEffect(()=> {
    if (data?.now_playing.remaining) {
      console.log('next song in', data.now_playing.remaining);
      setTimeout(() => {
        refetch();
      }, data.now_playing.remaining * 1000);
    }
  }, [data])

  return {
    ...data
  }
}