
type PlayButtonProps = {
  onClick: () => void;
  isStopped: boolean;
}
function PlayButton({
  onClick,
  isStopped,
}:PlayButtonProps):JSX.Element {
  return (
    <div className="PlayButton" onClick={onClick}>
      {isStopped ? 
        <svg width="34" height="34" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27.3516 18.6147C29.3906 17.4678 29.3905 14.5322 27.3516 13.3853L4.47078 0.514816C2.47097 -0.610079 0 0.835063 0 3.12954V28.8705C0 31.1649 2.47098 32.6101 4.47079 31.4852L27.3516 18.6147Z" fill="white"/>
        </svg>
        :
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="32" height="32" rx="3" fill="white"/>
        </svg>
      }
    </div>
  );
}

export default PlayButton;
