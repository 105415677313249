import './AboutPage.scss';

function AboutPage() {

  return (
    <div className="AboutContainer">
      <div className="AboutSection">
        <span>SINCE 2023</span>
        <div className="shape" id="shape1">
          <p className="text1">We've been discussing our own radio for a few years now, but we've been too busy and a bit lazy to start working on it.</p>
          <p className='text2'>So, 2023. War in Ukraine. The air siren is now your morning alarm clock. Perfect time.</p>
        </div>
      </div>
      <div className="AboutSection">
        <span>Created for myself, friends and family</span>
        <div className="shape" id="shape2">
          <p className="text1">THIS IS NON-COMMERCIAL RADIO, CALL IT AN ATTEMPT TO SHARE A GOOD MUSIC FOR MY MATES WITH 9 to 5 vibe.</p>
        </div>
      </div>
      <div className="AboutSection">
        <span>COULD BE CLOSED ANYTIME IF I / WE GET BORED</span>
        <div className="shape" id="shape3">
          <p className="text1">Life is so dynamic and unpredictable! To run this radio we need to support it everyday, </p>
          <p className="text2">Playlists, servers and monthly payments, all that shit. It's possible that one day we gonna say “fuck it im outta here”.</p>
          <p className="text3">And that's the most satisfying and fun part. “We build a radio and than we just ruined it for no reason”. Sounds badass.</p>
          <p className="text4">We'll see how long we could stay in the game. </p>
        </div>
      </div>
      <div className="AboutSection">
        <span>100 percent Ukrainian</span>
        <div className="shape" id="shape4">
          <p className="text1">50 percent of your donations we'll send to Ukrainian army </p>
          <p className="text2">this is not something that we </p>
          <p className="text3">should be proud of</p>
          <p className="text4">this is just a part of our existence nowadays</p>
        </div>
      </div>
      <div className="AboutSection">carefully selected playlists</div>
      <div className="AboutSection">
        <span>TELL YOUR FRIENDS ABOUT IT NOW</span>
        <div className="shape" id="shape6">
          <p className="text1">look.</p>
          <p className="text2">Let us be honest with you. we're really bad at</p>
          <p className="text3">marketing and advertising our own things.</p>
          <p className="text4">This radio was built on passion and fun. but of course we would like to make it something big and important for someone we don't know.  could we make it iykyk thing but make it a bit popular?</p>
        </div>
      </div>
      <div className="AboutSection">Socials</div>
    </div>
  );
}

export default AboutPage;
